@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$phone-number-color: $color-primary !default;

.help-desk-phone-number {
	.phone-number-sva {
		margin: auto auto 10px;
	}

	&__number {
		display: inline-flex;
		align-items: center;
		justify-content: center;

		svg {
			margin-right: 10px;
		}
	}

	.separator {
		background-color: $grey-medium;
		margin-top: 15px;
		margin-bottom: 15px;
	}

	&__intro {
		span + span {
			margin-left: 3px;
		}
	}

	&__link {
		@extend %font-bold;
		color: $phone-number-color;
		font-size: 2.4rem;
		letter-spacing: 0.1rem;
		text-decoration: none;
	}

	&__info {
		display: flex;
		flex-direction: column;
		font-size: 1.2rem;
		text-align: center;
	}

	&__international {
		font-size: 1.2rem;

		.help-desk-phone-number__link {
			color: $phone-number-color;
			font-size: 1.2rem;
			letter-spacing: 0.05rem;
		}
	}

	&__email {
		font-size: 1.4rem;
		display: flex;
		justify-content: center;
		align-items: center;

		.relative-link {
			margin-left: 5px;
		}
	}
}
